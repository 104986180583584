import React from 'react';
import './App.css'
import BranchingDialogue from './BranchingDialogue';

function App() {
  return (
      <div className = "BranchingDialogueContainer">
        <BranchingDialogue />
      </div>
  );
}

export default App;