import React, { useState } from 'react';
import dialogueData from './dialogue.json';
import AsciiContainer from './AsciiContainer'; // Assuming the correct path to AsciiContainer

const BranchingDialogue = () => {
  const [dialogueIndex, setDialogueIndex] = useState(0);
  const dialogue = dialogueData;
  const [jsonToUse, setJsonToUse] = useState('ascii_normal1.json'); // Define jsonToUse state

  const handleChoiceClick = (nextIndex, animation) => {
    setDialogueIndex(nextIndex - 1);
    changeAsciiJson(animation);
  };

  const changeAsciiJson = (animation) => {
    let newJsonToUse = 'ascii_normal1.json'; // Default JSON

    if (animation === 'smile1') {
      newJsonToUse = 'ascii_smile1.json';
    } else if (animation === 'smile2') {
      newJsonToUse = 'ascii_smile2.json';
    } else if (animation === 'normal2') {
      newJsonToUse = 'ascii_normal2.json';
    }

    setJsonToUse(newJsonToUse); // Update jsonToUse state
  };

  const currentDialogue = dialogue[dialogueIndex];

  return (
    <div className = "fullContainer">
      <div className = "artDiv">
        <AsciiContainer jsonToUse={jsonToUse} />
      </div>
      <div className = "textDiv">
        <p>{currentDialogue.text}</p>
        <ul>
          {currentDialogue.choices.map((choice) => (
            <li key={choice.id}>
              <button onClick={() => handleChoiceClick(choice.nextIndex, choice.animation)}>
                {choice.text}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BranchingDialogue;
