import React, { useState, useEffect } from 'react';
import asciiArtData from './ascii_normal1.json'; // Import the default JSON
import asciiNormal2Data from './ascii_normal2.json';
import asciiSmileData from './ascii_smile1.json'; // Import the smile JSON
import asciiSmile2Data from './ascii_smile2.json'; // Import the frown JSON
import './App.css'; // Import the CSS file with your styles

const AsciiContainer = ({ jsonToUse }) => {
  const [currentAsciiIndex, setCurrentAsciiIndex] = useState(0);
  let asciiArtArray;

  // Set the appropriate ASCII art array based on the received JSON
  if (jsonToUse === 'ascii_smile1.json') {
    asciiArtArray = asciiSmileData.art.map(item => item.ascii_art);
  } else if (jsonToUse === 'ascii_smile2.json') {
    asciiArtArray = asciiSmile2Data.art.map(item => item.ascii_art);
  } else if (jsonToUse === 'ascii_normal2.json') {
    asciiArtArray = asciiNormal2Data.art.map(item => item.ascii_art);
  } else {
    asciiArtArray = asciiArtData.art.map(item => item.ascii_art);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentAsciiIndex(prevIndex => (prevIndex + 1) % asciiArtArray.length);
    }, 42); // Change ASCII art with 24 fps (1000ms / 24 fps ≈ 42 ms)

    return () => clearInterval(interval);
  }, [asciiArtArray.length]);

  return (
    <div className="asciiContainer">
      <pre>{asciiArtArray[currentAsciiIndex]}</pre>
    </div>
  );
};

export default AsciiContainer;